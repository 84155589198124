<template>
  <div id="portfolio">
    <nav class="top-nav">
      <div class="mobile-menu" v-if="isMobile" @click="openMobileMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="nav-content">
        <div class="nav-left">
          <router-link :to="{name: 'home'}" class="nav-title">Alex RAYER</router-link>
        </div>
        <div class="nav-right" :class="{'opened': menuMobileOpened}">
          <a href="#" class="menu-cross" v-if="isMobile">&times;</a>
          <router-link :to="{name: 'portfolio'}" class="nav-item">Portfolio</router-link>
          <router-link :to="{name: 'prints'}" class="nav-item" v-if="false">Prints</router-link>
          <router-link :to="{name: 'cart'}" class="nav-item"
                       v-if="false && ($route.name === 'prints' || $route.name === 'cart')">
            Cart
          </router-link>
          <router-link :to="{name: 'contact'}" class="nav-item">Contact</router-link>
        </div>
      </div>
      <div class="divider"></div>
    </nav>
    <section class="body">
      <router-view/>
    </section>
    <footer>
      <div class="social">
        <div class="social-infos">
          <span>Alex RAYER</span>
          <a href="mailto:rayer.apro@gmail.com" title="Mail to Alex RAYER">rayer.apro@gmail.com</a>
          <span>Photographe professionnel</span>
        </div>
        <div class="social-media">
          <a href="https://twitter.com/AlexRayer_" title="Alex RAYER Twitter" target="_blank" rel="nofollow">
            <svg class="twitter-icon" xmlns="http://www.w3.org/2000/svg" fill="black" width="24" height="24"
                 viewBox="0 0 24 24">
              <path
                  d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
            </svg>
          </a>
          <a href="https://www.flickr.com/people/192944185@N06" title="Alex RAYER Flickr" target="_blank"
             rel="nofollow">
            <svg class="flickr-icon" xmlns="http://www.w3.org/2000/svg" fill="black" width="24" height="24"
                 viewBox="0 0 24 24">
              <path
                  d="M17 5c-1.961 0-3.731.809-5.002 2.108-1.27-1.299-3.038-2.108-4.998-2.108-3.866 0-7 3.134-7 7s3.134 7 7 7c1.96 0 3.728-.809 4.998-2.108 1.271 1.299 3.041 2.108 5.002 2.108 3.866 0 7-3.134 7-7s-3.134-7-7-7zm0 12c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z"/>
            </svg>
          </a>
          <a href="https://www.instagram.com/lexay_pix" title="Alex RAYER Instagram" target="_blank" rel="nofollow">
            <svg class="instagram-icon" xmlns="http://www.w3.org/2000/svg" fill="black" width="24" height="24"
                 viewBox="0 0 24 24">
              <path
                  d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
            </svg>
          </a>
          <a href="https://www.youtube.com/c/alexrayer" title="Alex RAYER Youtube" target="_blank" rel="nofollow">
            <svg class="youtube-icon" xmlns="http://www.w3.org/2000/svg" fill="black" width="24" height="24"
                 viewBox="0 0 24 24">
              <path
                  d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"/>
            </svg>
          </a>
          <a href="https://www.linkedin.com/in/alex-rayer-87b599173" title="Alex RAYER LinkedIn" target="_blank"
             rel="nofollow">
            <svg class="linkedin-icon" xmlns="http://www.w3.org/2000/svg" fill="black" width="24" height="24"
                 viewBox="0 0 24 24">
              <path
                  d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/>
            </svg>
          </a>
        </div>
      </div>
      <div class="divider"></div>
      <div class="copyright">
        <span>Alex RAYER&copy; 2021. Tous droits réservés.</span>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isMobile: false,
      menuMobileOpened: false
    };
  },
  methods: {
    checkIsMobile() {
      this.isMobile = window.innerWidth < 800;
    },
    openMobileMenu() {
      if (this.isMobile) {
        this.menuMobileOpened = true;
        document.querySelector("html").style.overflow = "hidden";
      }
    },
    closeMobileMenu(e) {
      if (e.target.tagName === "A") {
        if (this.isMobile) {
          this.menuMobileOpened = false;
          document.querySelector("html").style.removeProperty("overflow");
        }
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.checkIsMobile);
    document.querySelector("div.nav-right")?.addEventListener("click", this.closeMobileMenu);
    this.checkIsMobile();
  },
  unmounted() {
    window.removeEventListener("resize", this.checkIsMobile);
    document.querySelector("div.nav-right")?.removeEventListener("click", this.closeMobileMenu);
  }
}
</script>

<style lang="less">
html, body {
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

.no-overflow {
  overflow: hidden;
}

div#app {
  width: 100vw;
  min-height: 100vh;
}

div#portfolio {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;

  @media all and (max-width: 800px) {
    nav.top-nav {
      & div.mobile-menu {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 50%;
        left: 10px;
        width: 35px;
        transform: translateY(-50%);

        & span {
          width: 80%;
          height: 3px;
          margin: 2px auto;
          background-color: black;
        }
      }

      div.nav-content {
        & div.nav-right {
          display: none;

          &.opened {
            position: fixed;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(250, 250, 250, .9);
            z-index: 50;
          }

          a {
            color: black;

            &.nav-item {
              margin: 20px auto;
              font-size: 2em;

              &:before {
                display: none;
                animation: none;
              }
            }
          }

          a.menu-cross {
            position: fixed;
            display: block;
            top: 0;
            right: 15px;
            text-decoration: none;
            font-size: 5em;
            z-index: 51;
          }
        }

        & div.nav-left {
          width: 100%;
        }
      }
    }
  }

  nav.top-nav {
    position: relative;

    div.nav-content {
      display: flex;
      justify-content: space-between;
      padding: 20px 30px;
      background-color: white;

      & > div {
        display: flex;
        align-content: center;
      }

      a.nav-title {
        margin: auto;
        font-size: 1.4em;
        color: black;
        text-decoration: none;
      }

      a.menu-cross {
        color: black;
      }

      a.nav-item {
        position: relative;
        margin: auto 15px;
        font-size: 1.2em;
        color: black;
        text-decoration: none;

        &:before {
          position: absolute;
          content: "";
          width: 100%;
          height: 3px;
          bottom: -2px;
          background-color: black;
          animation-name: nav-out;
          animation-duration: 0.3s;
          animation-timing-function: cubic-bezier(1, 0, 0.58, 0.97);
          animation-fill-mode: both;
        }

        &:hover:before {
          animation-name: nav-in;
        }

        @keyframes nav-in {
          0% {
            width: 0;
            left: 0;
            right: auto;
          }
          100% {
            width: 100%;
            left: 0;
            right: auto;
          }
        }

        @keyframes nav-out {
          0% {
            width: 100%;
            left: auto;
            right: 0;
          }
          100% {
            width: 0;
            left: auto;
            right: 0;
          }
        }
      }
    }
  }

  section.body {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;

    & > div {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;

    div.social {
      display: flex;
      flex-direction: column;
      margin: 15px auto 0;

      & > div {
        margin: 3px auto;
      }

      div.social-infos {
        display: flex;
        flex-direction: column;
        align-items: center;

        & > * {
          margin: 2px auto;
          text-align: center;
        }

        a {
          color: black;
        }
      }

      div.social-media {
        display: flex;
        width: 100%;
        justify-content: space-around;

        & > * {
          margin: 5px;

          & > svg {
            transition: fill .2s;
          }

          &:hover {
            & .twitter-icon {
              fill: #1DA1F2;
            }

            & .flickr-icon {
              fill: #FF0064;
            }

            & .instagram-icon {
              fill: #C13584;
            }

            & .linkedin-icon {
              fill: #0e76a8;
            }

            & .youtube-icon {
              fill: #c4302b;
            }
          }
        }
      }
    }

    div.copyright {
      display: flex;
      margin: 0 auto 15px;

      span {
        margin: auto;
        text-align: center;
      }
    }
  }

  div.divider {
    width: 80%;
    height: 2px;
    margin: 15px auto;
    background-color: black;
  }
}
</style>
