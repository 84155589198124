import {createRouter, createWebHistory} from "vue-router";

function loadView(component) {
    return () => import(`@/views/${component}.vue`);
}

const routes = [
    {
        path: "/",
        name: "home",
        component: loadView("Home")
    },
    {
        path: "/portfolio",
        name: "portfolio",
        component: loadView("Portfolio")
    },
    {
        path: "/portfolio/:project",
        name: "portfolio_project",
        component: loadView("PortfolioProject")
    },
    {
        path: "/prints",
        name: "prints",
        component: loadView("Prints")
    },
    {
        path: "/cart",
        name: "cart",
        component: loadView("Cart")
    },
    {
        path: "/contact",
        name: "contact",
        component: loadView("Contact")
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
